<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Districts">
                <delete-items-button v-if="$hasPermission($permissions.RemoveAreas)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>

                <b-button v-if="$hasPermission($permissions.AddAreas)" variant="primary" @click="addDistrictModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table ref="basicTable"  :columns="columns" :data="districts" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 3 && $hasPermission($permissions.RemoveAreas)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditAreas)" variant="warning" @click="openEdit(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveAreas)" variant="danger" @click="removeDistrict(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: props.formattedRow[props.column.field] }"></div>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>

            <b-modal title="Add district" v-model="addDistrictModalActive" no-close-on-backdrop>
                <template #default>

                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="addObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="addObject.color"/>

                    <b-form-group>
                        <label>Territories</label>
                        <v-select
                            v-model="addObject.territories"
                            :reduce="territory => territory.id"
                            label="name"
                            multiple
                            :options="territories"
                        />
                    </b-form-group>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="addDistrictModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="primary" @click="addDistrict">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add</span>
                    </b-button>
                </template>
            </b-modal>

            <b-modal title="Edit district" v-model="editDistrictModalActive" no-close-on-backdrop>
                <template #default>
                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="editObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="editObject.color"/>

                    <b-form-group>
                        <label>Areas</label>
                        <v-select
                            v-model="editObject.territories"
                            :reduce="territory => territory.id"
                            label="name"
                            multiple
                            :options="territories"
                        />
                    </b-form-group>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="editDistrictModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="warning" @click="saveDistrict">
                        <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Save</span>
                    </b-button>
                </template>
            </b-modal>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BOverlay, BModal, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import ColorPicker from '@/views/components/ColorPicker'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            ColorPicker,
            BCard,
            BButton,
            BOverlay,
            BasicTable,
            vSelect,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                districts: [],

                territories: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },

                columns: [
                    {
                        label: 'Select',
                        displayType: 3,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Number of territories',
                        displayType: 0,
                        field: 'territories',
                        formatFn: (value) => value.length,
                        sortFn: (x, y) => ((x.length < y.length) ? -1 : (x.length > y.length) ? 1 : 0),
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search',
                            filterFn: (data, filter) => Number(data.length) === Number(filter)
                        }
                    },
                    {
                        label: 'Color',
                        displayType: 2,
                        field: 'color',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addDistrictModalActive: false,
                addObject: {
                    name: '',
                    color: '#000000',
                    territories: []
                },

                editDistrictModalActive: false,
                editObject: {
                    name: '',
                    color: '#000000',
                    territories: []
                }
            }
        },
        methods: {
            openEdit(district) {
                this.editObject = JSON.parse(JSON.stringify(district))
                this.editDistrictModalActive = true
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/district')
                loadPromise.then(function(response) {
                    thisIns.districts = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const territoryLoadPromise = this.$http.get('/api/management/v1/territory')
                territoryLoadPromise.then(function(response) {
                    thisIns.territories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, territoryLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addDistrict() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/district', this.addObject)
                addPromise.then(function() {
                    thisIns.addDistrictModalActive = false
                    thisIns.$printSuccess('District added')
                    thisIns.loadData()
                    thisIns.addObject = {name: '', color: '#000000', territories: []}
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            saveDistrict() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/district/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.editDistrictModalActive = false
                    thisIns.$printSuccess('District saved')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            async removeDistrict(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this district?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/district/${  id}`)
                    this.$printSuccess('District removed') 
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        mounted() {
            this.selection.collection_name = 'districts'

            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>